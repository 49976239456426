import React, { useContext, useRef, useState } from "react";

// Queries
import ParticipantSummaryInfo from "../../components/sidebars/ParticipantSummaryInfo";
import { UserContext } from "../../context/userContext";
import { Menu } from "primereact/menu"
import { Button } from "primereact/button"
import ROUTES from "../../components/Routes/routesContants";
import { useNavigate, useParams } from "react-router-dom";
import TRChatMessagesIndex from "./ChatMessages/TRChatMessagesIndex";

export default function TelerecoveryServices() {
  const user = useContext(UserContext);
  const { type, peerId } = useParams()
  const [selectedType, setSelectedType] = useState(type)
  const navigate = useNavigate()
  const [staffId, setStaffId] = useState(null)
  const [staffFullName, setStaffFullName] = useState(null)

  const updateUrlParam = (trType) => {
    return navigate(ROUTES.PARTICIPANT_TELERECOVERY_SERVICES + "/" + trType)
  }

  const SideBar = () => {
    const menu = useRef(null)

    const items = [
      {
        label: "Chat Messages",
        command: () => {
          setSelectedType("chat")
          updateUrlParam("chat")
        },
      },
    ]

    const labelBasedOnType = () => {
      if (selectedType === "chat") {
        return "Chat"
      }
    }

    return (
      <div style={{ height: "62px" }}>
        <div>
          <Menu model={items} popup ref={menu} id="popup_menu" />
          <Button
            label={labelBasedOnType() + " Sessions"}
            icon="pi pi-angle-down p-button-icon-right"
            style={{
              background: "var(--branded-purple)",
              borderColor: "var(--branded-purple)",
              marginBottom: "20px",
            }}
            className="btn btn-primary"
            onClick={(event) => menu.current.toggle(event)}
            aria-controls="popup_menu"
            aria-haspopup
          />
        </div>
      </div>
    )
  }

  const changeSelectedConv = (staffId) => {
    navigate(`${ROUTES.PARTICIPANT_TELERECOVERY_SERVICES}/chat/${staffId}`)
  }

  React.useEffect(() => {
    if (user.staff_assigment_list) {
      let staff = Array.from(user.staff_assigment_list)[0];
      setStaffId(staff.staff_id)
      setStaffFullName({
        first: staff.staff_name_first,
        last: staff.staff_name_last,
        full_name: `${staff.staff_name_first} ${staff.staff_name_last}`
      })
    }
  }, [])

  return (
    <div className="layout-with-sidebar">
      <ParticipantSummaryInfo />

      <div className="main-content" style={{ minHeight: "744px" }}>
        <div style={{ marginTop: "20px" }}>
          {user.participant_membership_status === "Inactive Discharged" && (
            <span>
              You cannot use this Telerecovery module as you are currently in an "Inactive
              Discharged" status.
            </span>
          )}
          {user.participant_membership_status !== "Inactive Discharged" && (
            <>
              <SideBar></SideBar>
              {selectedType === "chat" && staffId && staffFullName && (
                <TRChatMessagesIndex
                  staffId={staffId}
                  staffName={staffFullName}
                  selectedConvStaffId={peerId}
                  changeSelectedConv={changeSelectedConv} />
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}
